.side-layer
{
    width: 100%;
    height: 100%;
    margin: 0px;
    &-form{
        background-color:white;
        height: 92%;
        border:1px solid rgba(222, 222, 224, 0.57);
        overflow-y: auto;
        .list-state-title {
            border-right: 4px solid #030F40;
            font-size: 18px;
            margin: 20px 0;
        }
        .list-state {
            &.first-list {
                li {
                    padding: 8px 0 8px 0;
                    .title{
                        padding-top: 16px;
                    }
                }
            }
        }
        .list-state{
            padding:0;
            margin: 0;
            width: 100%;
            text-align: center;
            li {
                cursor: pointer;
                display: inline-block;
                padding: 17px 0 17px 0;
                line-height: 1.2;
                border: 1px solid;
                height: 4rem;
                margin: 10px;
                text-align: left;
                width: 99%;
                height: 4rem;
                border-radius: 5px;
                img{
                    height: 50px;
                }
                @media screen and (max-width: 1200px) {
                    img{
                    height: 40px;
                    }
                } 
                &.disabled
                {
                  opacity: 0.4;
                }
                .title{
                    padding-top: 5px;
                }
            }
        }
        .create-project-side{
                border-top: #3D466C 4px solid;
                width: 100%;
                margin-bottom: 32%;
        }
        .add-project-btn
        {
          border: none;
            .add-circle{
                margin-right:2px;
            }
            span {
                text-transform: none;
            }
        }
    }

}
.potential-project-side-layer{
    height: 100%;
    @extend .side-layer;
    .create-source-side{
        position: absolute;
        border-top: #3D466C 4px solid;
        bottom: 0;
        width: 100%;
        margin-bottom: 32%;
    }
    .add-source-btn{
        background: none;
        .add-circle{
            margin-right:2px;
        }
        span {
            text-transform: none;
        }
    }
}
.circle{
    position: fixed;
    z-index: 1001;
    height: 80px;
    width: 80px;
    background-color: rgb(249, 138, 3);
    opacity: 0.95;
    border-radius: 50%;
    display: inline-block;
    left: 46%;
    padding: 28px;
}

