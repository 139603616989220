.project-container {
  .detail-project {
    box-shadow: 0 7px 10px rgba(0,0,0,.27);
    overflow-y: auto;
    background-color: white;
    height: 100%;
    .view-project {
      .status-img{
        margin-top: -10px;
        margin-left: -12px;
        max-width: 40px;
      }
      .state-span
      {
        height: 20px;
        width: 20px;
      }
      .detail-photo{
        max-width: 300px;
        max-height: 300px;
        border:2px solid var(--gray-light);
      }
      .details-collapse {
        .sz-card-collapse__header {
          padding: 5px;
          background: var(--primary) !important;
          color: var(--white);

          .card-header {
            background: none !important;
            border-left: none !important;

          }

          &:after {
            background: none !important;
          }
        }
        .details-attachments
        {
          .attachment-icon
          {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.confirmation-buttons{
  margin-top: 25px;
}

.project-menu {
  justify-content:flex-end;
  .open {
      padding: 5px;
  }
  .alarm-slash {
    position: relative;
    i:after {
      position: absolute;
      content: "/";
      font-size: 1.7em;
      top: -24px;
      left: 15px;
    }
  }
}

.contact-menu {
  justify-content: flex-end;
  margin-top: -55px;
  margin-right: 25px;
}

.container-margin-top{
  margin-top: 70px;
}
