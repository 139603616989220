.notification-list
{
    overflow: auto;
    height: inherit;
    .notification-project{
        &_content{
         background-color: white;
         .detail-photo{
                width:200px;
                height: 200px;
                border: 2px solid white;
            }
        }
        &_title{
            text-transform: uppercase;
        }
      .notification-project_content {
        position: relative;
        .project-notification-menu {
          position: absolute;
          right: 15px;
          top: 3px;
        }
      }
    }
    &_tab__nav{
        a{
            margin-right: 3rem !important;
        }
    }
    .sz-notification-number{
        font-size: initial;
    }
    
    
}

.notification-header {
        background-color: white;
        border-bottom: 2px solid rgba(222, 222, 224, 0.57);
        border-top: 2px solid rgba(222, 222, 224, 0.57);

        .go-home {
            height: 40px;
        }

        .sz-card-collapse__header {
            padding: 10px;
        }
}

.notification-bull{
    position: absolute;
    top: 0;
    background: #eb4964;
    border-radius: 10px;
    width: 2%;
    text-align: center;
    color: white;
    &_tab-0{
        left: 25rem;
    }
    &_tab-1{
        left: 38rem;
    }
    &_tab-2{
        left: 50rem;
    }
}

.text-decoration-underline{
    text-decoration: underline;
}