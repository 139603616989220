.robot-container{
    width: 100%;
    height: 100%;
    margin: 0px;
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scroll::-webkit-scrollbar {
      display: none;
    }
    .hide-scroll{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .potential-project-container{
      max-height: 95%;
    }
}
.w-98{
    width: 98% !important;
}
.details{
    position: fixed;
    z-index: 100;
}
.data-source-form-container {
  border: 1px solid rgba(222, 222, 224, 0.57);
  overflow-y: auto;
  box-shadow: 0 5px 0 5px rgba(222, 222, 224, 0.57);
}
.create-data-source {
    label {
      &.required {
        &:after {
  
          float: left;
          right: auto;
  
        }
      }
    }
  
    .depend_required {
      label {
        &:after {
          content: '**';
          position: absolute;
          color: var(--danger);
          right: auto;
        }
      }
    }
  }

.sz-robot-title {
  height: 45px ;
  padding-top: 10px;
  .sz-robot-number {
    left: 145px;
    bottom: 0px;
    min-width: 35px;
    min-height: 35px;
    padding-top: 6px !important;
  }
}
