.project-container
{
    width: 100%;
    height: 92%;
    margin: 0px;
    .project-form{
        background-color:white;
        height: 100%;
        border:1px solid rgba(222, 222, 224, 0.57);
        overflow-y: auto;
        box-shadow: 0 5px 0 5px rgba(222, 222, 224, 0.57);
        label:after
        {
            float: left;
            right: auto;
        }
        .input-group
        {
            padding-bottom:5px;
            margin-bottom: 0 !important;
            input{
               height: 58px;
            }
        }
        .react-date-picker
        {
            width: 100%;
            .react-date-picker__wrapper
            {
                border: 2px solid var(--light);
                width: 100%;
            }
        }
        .upload-zone {
            border-style: dashed;
            text-align: center;
            border-width: 2px;
            border-radius: 2px;
            border-color: var(--gray);
        }
        .dropzone {
            text-align: center;
            border-width: 2px;
            border-radius: 2px;
            border-color: var(--gray);
            border-style: dashed;
            outline: none;
            transition: border .24s ease-in-out;
            text-transform: uppercase;
            height: 50px;
            padding-top: 10px;
          }
          .dropzone:focus {
            border-color: var(--gray-light);
          }
          .form-control-file{
              display: none;
              float: left;
          }
        .delete-file{
            color:red;
            cursor: pointer ;
        }
        .sz-label-input{
            font-weight: bold;
        }
        .add-contact
        {
          cursor: pointer;
        }
    }
    .go-home{
        cursor: pointer;
    }

    .project-header {
        background-color: white;
        border-bottom: 2px solid rgba(222, 222, 224, 0.57);
        border-top: 2px solid rgba(222, 222, 224, 0.57);

        .go-home {
            height: 40px;
        }

        .sz-card-collapse__header {
            padding: 10px;
        }
    }
}
.robot-container{
    @extend .project-container;
    .back-btn{
        background: none;
        box-shadow:none;
    }
    .url{
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .label{
        white-space: pre-line;
    }
    .potential-project-table table thead th{
        padding: 0.75rem !important;
        padding-left: 1.2rem !important;
    }
    .potential-project-table table tbody, .potential-project-table table tbody tr {
        .url{
            max-width: 350px;
        }
        .label{
            max-width: 350px;
        }
        .crawlLevel{
            text-align: center;
            max-width: 130px;
        }
    }
    .header-crawlLevel{
        max-width: 130px;
    }
    .header-url{
        min-width: 350px;
    }
    .header-label{
        min-width: 350px;
    }
}
.form-actions {
    .btn-secondary:not(:disabled) {
        border-color: #00a4b6 !important;
        background: #00a4b6 !important;

        &.sz-btn-outline {
            background: white !important;
        }
    }
}
.sz-form-group__input-group-icon {
    z-index: 0;
}

.sz-form-group__input-group-icon {
    z-index: 0;
}

.input-group-contact
{
    .sz-form-group__input-group-icon {
        z-index: 5;
    }
}

.list-group {
    max-height: 250px;
    z-index: 100 ;
    width: 95% !important;
    top: 80px ;

    &.contact-auto-complete-zac {
        top: 250px ;
    }
}
.no-main-contact {
    padding-left: 30px;
}
.list-radio {
    padding-left: 15px;
    .list-radio-contact {
        border: 1px solid rgba(222, 222, 224, 0.57);
        .delete-contact{
            color:#00a4b6;
            cursor: pointer ;
        }
    }
}

.list-style-none{
    list-style: none;
}

.duplicated-project-list {
    max-height: 110px;
    overflow: auto;
}

.potential-project-table, .list-table, .contact-table, .zac-table
{
    div{
        width: 100% !important;
    }
}

.contact-container
{
  .contact-table {
      overflow: auto;
  }
}

.zac-container
{
    .zac-table {
        overflow: auto;
    }
}

.zac-form {
    background-color: white;
    height: 100%;
    border: 1px solid rgba(222, 222, 224, 0.57);
    overflow-y: auto;
    box-shadow: 0 5px 0 5px rgba(222, 222, 224, 0.57);
}

.side-layer {
    .alarm-slash {
        position: relative;

        i:after {
            position: absolute;
            content: "/";
            font-size: 1.7em;
            top: -14px;
            left: 6px;
        }
    }
}