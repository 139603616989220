.argus-container {
  height: calc(100% - 60px);
  display: flex;
  flex-wrap: nowrap;

  &.connected {
    height: 100%;
    width: 100%;
    transition: 0.5s ease-in;
  }
  &.isOpen {
    transition: 0.3s ease;
  }
}
.argus-aside {
  height: 100%;
  &.open{
    .argus_admin {
      &::before {
        width: 100%;
      }
    }
  }
  .argus_admin {
    bottom: 40px;
    position: absolute;
    width: fit-content;
    padding-top: 16px;
    padding-bottom: 16px;

    &::before {
      content: '';
      position: absolute;
      background: var(--primary-brand);
      top: 0;
      bottom: 90%;
      left: 0;
      width: 100%;
    }

    &__item {
      height: 60px
    }
  }
}
.argus-main-container {
    display: flex;
    // 45 = height of header
    height: calc(100% - 45px);
}
.no-padding{
  padding: 0;
}

.clickable {
  cursor: pointer !important;
}

.container-content{
  width: 100%;
}
.search-box{
  .input-group{
    margin-bottom: 0px !important;
  }
}

