.map{
    width: 100%;
    height: 100%;
}

.hidden{
    visibility: hidden;
    display:none;
}

.close-pane{
    position: absolute;
    z-index: 999;
    width: 5%;
    height: 3rem;
    text-align: center;
    background-color: #030f40;
    color: white;
    left: -10px;
    font-size: 25px;        
}

.open-pane{
    width: 2%;
    background-color: white;
    div{
        text-align: center;
        background-color: #030f40;
        color: white;
        font-size: 25px;
    }
}

.state-span{
    @media screen and (max-width: 1200px) {
        height: 20px;
        width: 20px;
    } 
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: inline-block;

    &.to-follow{
        background-color: #30D158;
    }
    &.to-not-follow{
        background-color: #800E21;
    }
    &.to-follow-not-connectable {
        background-color: #FF8363;
    }

    &.toDefine {
        background-color: #919191
    }
}

.list-table {
    .status-img{
        max-width: 50px;
    }
}

.flex-container{
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
}
.flex-side-layer{
    flex: 0 0 300px;
    max-width: 300px;
    position: relative;
    width: 100%;
}
 @media screen and (max-width: 1200px) {
    .flex-container{
        flex: 0 0 80%;
        max-width: 80%;
    }
    .flex-side-layer{
        flex: 0 0 20%;
        max-width: 20%;
    }
} 
 @media screen and (max-width: 960px) {
    .flex-container{
        flex: 0 0 82%;
        max-width: 82%;
    }
    .flex-side-layer{
        flex: 0 0 18%;
        max-width: 18%;
    }
}
