@import "./main/styles/_colors";
@import './main/styles/fonts';

html, body, #argus-urba-app {
  height: 100%;
  background-color: #EAEDF4;
  font-family: 'DINPro-Regular';
}
.argus-content-container {
  max-width: 1100px;
}

.h-90{
  height: 90%;
}

.text-secondary {
  color: $primary-color !important;
}

.sz-aside__item.active, .sz-aside__item.active:hover {
  color: $primary-color;
}
.sz-aside__item {
  padding-left: 1rem;
}

.list-table {
  max-height: 790px;
  th {
    &.address{
      min-width: 130px;
    }
    &.hold{
      min-width: 130px;
    }
    &.contact{
      min-width: 150px;
    }
    font-size: 0.875rem !important;
    font-weight: bold !important;


    &.sz-icon-line {
      font-size: 1rem;
      font-family: 'DINPro-Regular' !important;
      cursor: pointer;
    }
    font-family: 'DINPro-Regular' !important;
    background-color: var(--table-list-header-bg);
  }
  tr{
    cursor: pointer;
    &.selected-project{
      background: var(--light);
    }
  }
  td.hold {
    text-align: right;
  }
}
.potential-project-table{
  height: 90%;
  @extend .list-table;
  tr {
    cursor:default;
  }
}

.contact-table, .zac-table{
  height: 90%;
  @extend .list-table;
  tr {
    cursor:default;
  }
}
.sz-form-group__sz-input-text {
  -webkit-box-align: center;
  align-items: center;
  background-color: white !important;
  box-shadow: none;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 58px;
  position: relative;
  box-sizing: border-box;
  border-color: rgb(231, 232, 238) !important;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  transition: all 100ms ease 0s;
  outline: 0px !important;

  &:hover {
    border-color: black !important;
    border-width: 1px;
  }
}
.sz-form-group__input-group-icon {
  &.reset-search {
    left: auto;
    right: 13px;
    top: 13px;
  }
}
.react-date-picker__wrapper{
  &::before{
    content:''
  }
}

@media (max-height: 900px) {
  .list-table {
    max-height: 690px;
  }
}
.argus-choose-btn{
  label{
    padding-left: 0px !important;
  }
}
.argus-text-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
}

.sz-aside.sz-aside-exit-done {
  width: 54px;
}
.react-date-picker__calendar-button{
  .arrow-down-1{
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
}

.required_errors{
  .error {
    color: var(--danger) !important;
  }
}

.react-date-picker__calendar-button
{
  .arrow-down-1
  {
    display: none !important;
  }
}

.modal-content
{
  color: var(--secondary) !important;
}

.input-text-pencil
{
  &.zac{
    input {
      bottom: 40px;
    }
    i{
      top:10px;
      left:219px;
    }
  }
  i {
    position: relative;
    top: 32px;
    float: left;
    z-index: 5;
    left:465px;
  }
  input {
    bottom: 20px;
    background-color: var(--gray-0)!important;
  }
}
.sz-button-group{
  label {
    color: var(--secondary) !important;
  }
}

.required:after {
  content:" *";
  color: var(--danger);
  position: absolute;
}
.required-div{
  label:after{
    content:" *";
    color: var(--danger);
    position: absolute;
    font-weight: bold;
  }
}

.react-bootstrap-table {
  th{
    &.order-row {
      &:before {
        margin-left: 3.5px;
        content: "\2191";
      }
      &:after{
        content: "\2193";
      }
      &.order-th:before {
        opacity: .4;
      }
      &.order-th:after{
        opacity: .4;
      }
      &.order-desc:before {
        opacity: .4;
      }
      &.order-asc:after {
        opacity: .4;
      }
    }
    &:after {
      left: 15px;
      position: relative;
    }
    &.address{
      &:before {
        left: 90px;
        position: relative;

      }
    }
    &.name{
      &:before {
        left: 110px;
        position: relative;

      }

    }

    &.contact{
      &:before {
        left: 115px;
        position: relative;

      }
    }
    &.hold{
      &:before {
        left: 90px;
        position: relative;

      }
    }
    &.url{
      &:before {
        left: 110px;
        position: relative;

      }
    }
    &.probability {
      &:before {
        left: 84px;
        position: relative;

      }
    }

    &.createdAt {
      &:before {
        left: 45px;
        position: relative;

      }
    }
    &.contactFirstName
    {
      &:before {
      left: 100px;
      position: relative;
    }}
    &.contactLastName
    {
      &:before {
        left: 80px;
        position: relative;
      }}
    &.contactSocialReason
    {
      &:before {
        left: 140px;
        position: relative;
      }}
    &.contactType
    {
      &:before {
        left: 80px;
        position: relative;
      }}
    &.zacName
    {
      &:before {
        left: 90px;
        position: relative;
      }}
    &.zacArea
    {
      &:before {
        left: 120px;
        position: relative;
      }}
    &.zacContact
    {
      &:before {
        left: 90px;
        position: relative;
      }}

  }
}
.img-icon-btn
{
  width: 20px;
}

