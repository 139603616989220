.project-container {
  .project-form {
    .add-contact {
      cursor: pointer;
    }
  }
}

.create-contact {
  label {
    &.required {
      &:after {

        float: left;
        right: auto;

      }
    }
  }

  .depend_required {
    label {
      &:after {
        content: '**';
        position: absolute;
        color: var(--danger);
        right: auto;
      }
    }
  }
}

.duplicated-project-list
{
  max-height: 200px;
  overflow: auto;
  td {
    background-color: #eaedf4;
    border: 1px solid #fff;
    height: 35px;
    &.name {
      width: 40%;
    }
    &.address {
      width: 50%;
    }
    &.link {
      text-align: center;
    }
  }
}