@import '~leaflet/dist/leaflet.css';

.leaflet-container {
  height: 92%;
  width: 100%;
  .leaflet-popup {
    .leaflet-popup-close-button {
      display:none;
    }
  }
  .leaflet-draw-toolbar{
    .leaflet-draw-draw-polygon {
        background-position: -54px -6px;
        width: 35px;
        height: 35px;
        background-size: 484px auto;
    }
    .leaflet-draw-edit-edit {
        background-position: -212px -3px;
        width: 35px;
        height: 35px;
        background-size: 420px auto;
    }
    .leaflet-draw-edit-remove {
        background-position: -207px -2px;
        width: 35px;
        height: 35px;
        background-size: 346px auto;
    }
  }
  .leaflet-control-container{
    .leaflet-control-zoom-in{
        width: 35px;
    height: 30px;
    background-size: 484px auto;}

    .leaflet-control-zoom-out{
        width: 35px;
    height: 30px;
    background-size: 484px auto;}
  }
}

.project-container{
  .leaflet-container {
    height: 100%;
  }
}

.zac-container{
  .leaflet-container {
    height: 100%;
  }
}

.sig-btn-menu {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 5px;
  width: 48px;
  height: 44px;
  text-align: center;
  background-color: #fff;
  i {
    margin-top: 10px;
  }
}

.map-sig-menu {
  min-width: 120px;
}

.box-shadow {
  background: var(--white);
  box-shadow: 0 0 1px 0 rgba(17,95,251,.05), 0 2px 4px 0 rgba(17,95,251,.1);
  padding: 8px 16px;
  .slider
  {
    width: 40px;
    height: 16px;
    &:before {
    height: 10px;
    width: 10px;
  }
  }
  .list-group-item
  {
    border: none;
  }
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  width: 44px !important;
}


.show-marker-info {
  top:10px;
  position: absolute;
  z-index: 999;
}
